.App {
	text-align: center;
}



.login {
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

h4 {
	font-weight: bold;
	font-size: 0.8rem;
}

.subtitle {
	color: #ccc;
	margin-top: -5px;
	margin-bottom: 5px;
	font-weight: bold;
	font-size: 0.8rem;
}

.clients-screen {
	max-width: 100vw;
    display: flex;
    justify-content: center;
}


.client-card {
    display: flex;
    padding: 0.5rem 0.5rem;
    margin: 0.5rem;
    background-color: #fff;
    border-radius: 0.25rem;
    border: none;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 7rem;
}

.modal-background {
	position: relative;
	margin-left: auto;
	margin-right: auto;
	margin-top: 1.5rem;
	margin-bottom: 1.5rem;
	width: auto;
	max-width: 48rem;
}

.modal {
	display: flex;
	position: relative;
	background-color: #ffffff;
	flex-direction: column;
	width: 100%;
	border-radius: 0.5rem;
	border-width: 0;
	outline: 0;
	box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
		0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.modal-title-container {
	display: flex;
	padding: 1.25rem 1rem 0rem 1rem;
	justify-content: space-between;
	align-items: flex-start;
	border-top-left-radius: 0.25rem;
	border-top-right-radius: 0.25rem;
}

.modal-black-background {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 40;
	background-color: #000000;
	opacity: 0.25;
}

.arrow-left {
	position: relative;
	width: 0;
	height: 0;
	border-bottom: 40px solid #9097bd;
	border-left: 40px solid transparent;
	right: -34px;
	bottom: -11px;
}

.modal-content {
	position: relative;
	padding: 0rem 0.75rem 0.75rem;
	flex: 1 1 auto;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

.pre-login-card {
	background-color: #fff;
	padding: 20px;
	border-radius: 10px;
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text-button-connexion {
	padding-right: 13px;
}

.button-div{
	display: inline-block;
}

.text-connection {
	font-size: 0.7rem;
    font-weight: bold;
}

.auth0-button {
	display: flex;
    background-color: #fff;
    background-size: cover;
    background-position: center;
    border: 1px solid #eee;
    border-radius: 10px;
    align-items: center;
}

.auth0-button:hover {
	background-color: #eee;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.arrival-card {
	display: flex;
	padding: 0.5rem 0.5rem;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	background-color: #d1d5db;
	justify-content: space-evenly;
	border-radius: 0.25rem;
}

.modal-title {
	font-size: 1rem;
	line-height: 2.25rem;
	font-weight: 600;
}

.product-stock-card {
	overflow: hidden;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	margin-left: 0.5rem;
	margin-right: 0.5rem;
	background-color: #ffffff;
	border-radius: 0.7rem;
	padding: 10px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.footer {
	position: fixed;
	top: auto;
	bottom: 0;
	width: 100%;
	height: 40px;
	padding-bottom: 10px;
	background-color: rgba(255, 255, 255, 0.9);
	color: rgb(92, 92, 92);
	--tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
	box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
		var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.text404 {
	margin-top: -7vw;
	font-size: 24vw;
	color: white;
	font-weight: 900;
	position: relative;
}

.page404 {
	margin-top: 2vw;
	font-size: 4vw;
	color: white;
	font-weight: 900;
	position: relative;
}

.globe {
	display: flex;
	align-content: center;
	justify-content: center;
}
